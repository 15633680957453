<template>
  <v-sheet
    rounded="xl"
    class="text-start pa-6 py-8"
    :width="$vuetify.breakpoint.mdAndUp ? '786px' : $vuetify.breakpoint.smAndUp ? '586px' : ''"
  >
    <stepper :currentStep="1" />
    <v-row class="px-3">
      <v-col cols="12" class="d-flex justify-space-between py-0">
        <div class="text-body-1">Address and amount</div>
        <v-btn
          color="primary lighten-1"
          class="text-body-1"
          small
          text
          v-if="isUploadFile"
          @click="isUploadFile = !isUploadFile"
          >Add manually</v-btn
        >
        <v-btn color="primary lighten-1" class="text-body-1" small text v-else @click="isUploadFile = !isUploadFile"
          >Add by file</v-btn
        >
      </v-col>
      <v-col cols="12" v-show="isUploadFile" class="pb-1">
        <DropDragableUpload @uploaded="uploaded($event)" style="min-height: 342px" />
      </v-col>
      <v-col cols="12" v-show="!isUploadFile" class="pb-1">
        <v-sheet rounded="xl" class="secondary lighten-3 pa-4">
          <codemirror :options="cmOption" ref="codeMirror" placeholder="" :code="plainData" />
        </v-sheet>
      </v-col>

      <v-col cols="12" v-if="duplicateCount || errorCount" class="pb-1">
        <v-sheet
          rounded="xl"
          class="secondary lighten-3 pa-6 frame-error"
          :style="`border-color: var(--v-error-lighten1) !important`"
        >
          <div class="mb-6 text-medium font-weight-medium">The below can not be processed</div>
          <div>
            <div class="d-flex justify-space-between" v-if="errorCount">
              <div class="text-body-1 mb-3 grey--text">{{ errorCount }} below is valid addresses or wrong amount</div>
              <v-btn
                class="primary--text pr-0 text-body-1"
                small
                text
                @click="deleteLines()"
                :loading="deleteLoading"
                :disabled="combineLoading"
                >Delete them</v-btn
              >
            </div>
            <div class="error-scrollable">
              <div v-for="(item, index) in Object.keys(errorList)" :key="index">
                Line {{ item }}: {{ errorList[item] }}
              </div>
            </div>
          </div>
          <v-divider v-if="errorCount && duplicateCount" class="my-6" />
          <div>
            <div class="d-flex justify-space-between" v-if="duplicateCount">
              <div class="text-body-1 mb-3 grey--text">{{ duplicateCount }} below address is dupplicated</div>
              <v-btn
                class="primary--text pr-0 text-body-1"
                small
                text
                @click="handleCombine()"
                :disabled="deleteLoading"
                >Combine balance</v-btn
              >
            </div>
            <div class="error-scrollable">
              <div v-for="(item, index) in Object.keys(duplicateList)" :key="index">
                Line {{ item }}: {{ duplicateList[item] }}
              </div>
            </div>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12" v-else-if="isEmptyError" class="pb-1">
        <v-sheet
          rounded="xl"
          class="secondary lighten-3 pa-6 frame-error"
          :style="`border-color: var(--v-error-lighten1) !important`"
        >
          <div class="line-error">Please upload or insert address with amount</div>
        </v-sheet>
      </v-col>
      <v-col cols="12" class="pa-0">
        <MenuDownloadSample v-if="isUploadFile" />
      </v-col>
      <v-col cols="12" class="d-flex py-0 justify-space-between mt-4">
        <v-btn rounded outlined min-width="150px" @click="$router.go(-1)">Back</v-btn>
        <v-btn rounded class="primary" min-width="150px" @click="next" :loading="nextLoading">Next</v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/display/placeholder.js'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    codemirror,
    DropDragableUpload: () => import('@/components/base/DropDragableUpload.vue'),
    Stepper: () => import('@/components/base/Stepper.vue'),
    MenuDownloadSample: () => import('@/components/base/MenuDownloadSample.vue'),
  },
  data() {
    return {
      claimId: '',
      plainData: '',
      isUploadFile: false,
      cmOption: {
        focus: true,
        placeholder: 'Insert address and amount, separate with comma',
        tabSize: 1,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        deleting: false,
      },
    }
  },
  computed: {
    ...mapState('auth', ['account', 'multiClaimHandler']),
    ...mapState('claimDetailByOwner', ['token']),
    ...mapState('claimAddWallet', [
      'errorList',
      'duplicateList',
      'isEmptyError',
      'editor',
      'nextLoading',
      'combineLoading',
      'deleteLoading',
      'codeMirrorText',
    ]),
    ...mapGetters('claimAddWallet', ['errorCount', 'duplicateCount']),
  },
  mounted() {
    this.claimId = this.$route.params.id
    this.fetchData({ editor: this.$refs.codeMirror.codemirror.doc, multiClaimHandler: this.multiClaimHandler })
    this.plainData = this.codeMirrorText
    this.$refs.codeMirror.codemirror.on(
      'change',
      function () {
        if (this.isEmptyError) {
          this.changeEmptyError(false)
        }
      }.bind(this)
    )
  },
  methods: {
    ...mapActions('claimAddWallet', [
      'nextStep',
      'deleteLines',
      'deleteLine',
      'handleCombine',
      'fetchData',
      'validateData',
      'changeEmptyError',
    ]),
    async next() {
      const res = await this.nextStep({ id: this.claimId, decimals: this.token ? this.token.decimals : 18 })
      if (res) this.$router.push('step=2')
    },
    uploaded(res) {
      this.isUploadFile = false
      this.editor.setValue(res)
      this.$refs.codeMirror.refresh()
      this.validateData()
    },
  },
  async created() {},
}
</script>

<style>
.v-input__slot {
  background: var(--v-secondary-lighten3) !important;
}
input {
  color: white !important;
}
.CodeMirror-gutters {
  width: 50px !important;
  border-right: 0px;
  background: transparent !important;
}
.CodeMirror {
  background: transparent !important;
  height: 310px;
  color: white;
  font-size: 16px !important;
  line-height: 270% !important;
}
.line-error {
  color: var(--v-error-lighten1) !important;
  font-size: 16px;
}
.CodeMirror-selected {
  background: #3297fd !important;
}
.frame-error {
  font-size: 16px;
  border: 1px solid var(--v-error-lighten1) !important;
}
.CodeMirror div.CodeMirror-cursor {
  border-left: 1px solid white;
}
.CodeMirror pre.CodeMirror-placeholder {
  color: #999;
}
.v-stepper__step--complete span i {
  color: green !important;
}
.text-medium {
  font-size: 20px !important;
  font-weight: 500 !important;
}
.error-scrollable {
  overflow-y: scroll;
  max-height: 120px;
}
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
  }
}
</style>
        